import { Grid, TextField, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { ErrorModal } from "@dexteel/mesf-core";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { FooterDetailsButtons } from "../../../../controls/buttons/FooterDetailsButtons";
import { NotificationSnackBar } from "../../../../controls/snackbars/notification-snackbar";
import { Roll } from "../../../../shared/models/Roll";
import { Rollset } from "../../../../shared/models/Rolllset";
import {
  CassetteSelector,
  ChockSelector,
  GuideSelector,
  LocationSelector,
  RollSelector,
  RollsetSelector,
  SectionSelector,
} from "../../../../shared/selectors";
import { FilterRollModal } from "./FilterRollModal";

import { useRolls } from "../../../../shared/selectors/RollSelector";
import { Carrier, CARRIER_INITIAL_VALUES } from "../../models/Carrier";
import { assemblyCarrier } from "../../repositories/CarrierAssemblyRepository";

type Props = {
  carrier: Carrier | null;
  modal: "assembly" | "disassembly" | "";
  onHide: ({
    shouldUpdate,
    close,
  }: {
    shouldUpdate: boolean;
    close: boolean;
  }) => void;
};

export const CarrierAssemblyDetails = ({ carrier, modal, onHide }: Props) => {
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [selectedRolls, setSelectedRolls] = useState<Roll[]>();
  const [selectedRollset, setSelectedRollset] = useState<Rollset>();
  const [forceAssembly, setForceAssembly] = useState(false);

  const succesfullyMessage = `The carrier was ${modal}d succesfully`;

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    watch,
    getValues,
    formState: { dirtyFields },
  } = useForm<Carrier>({
    defaultValues: CARRIER_INITIAL_VALUES,
    mode: "onChange",
  });

  const isFormDirty = !(Object.keys(dirtyFields).length === 0);

  const validateChocks = (
    value: number | null,
    otherValues: { Chock: "LM" | "UM" | "LO" | "UO" }[]
  ) => {
    const firstValue = watch(`${otherValues[0].Chock}ChockId`);
    const secondValue = watch(`${otherValues[1].Chock}ChockId`);
    const thirdValue = watch(`${otherValues[2].Chock}ChockId`);
    let sameValue;
    if (value === firstValue) sameValue = otherValues[0].Chock;
    if (value === secondValue) sameValue = otherValues[1].Chock;
    if (value === thirdValue) sameValue = otherValues[2].Chock;
    return (
      value === null ||
      value === undefined ||
      !sameValue ||
      `Cannot be the same as ${sameValue} Chock`
    );
  };

  const { data: rolls } = useRolls({
    onError: (error) => setError(error.message),
  });

  const handleHideFilter = (save: boolean) => {
    if (save) {
      if (selectedRollset) {
        setValue("TopRollId", selectedRollset.DefaultTopRollId);
        setValue("BottomRollId", selectedRollset.DefaultBottomRollId);
      } else if (selectedRolls) {
        setValue(
          "TopRollId",
          selectedRolls.find((roll) => roll.RollPosition === "T")?.PartId ??
            selectedRolls[0].PartId ??
            null
        );
        setValue(
          "BottomRollId",
          selectedRolls.find((roll) => roll.RollPosition === "B")?.PartId ??
            selectedRolls[1].PartId ??
            null
        );
      }
    }
    setShowFilterModal(false);
  };

  const queryClient = useQueryClient();
  const upsertMutation = useMutation(
    ({ data, onForceAssembly }: { data: Carrier; onForceAssembly: boolean }) =>
      assemblyCarrier(data, onForceAssembly),
    {
      onSuccess: async (id: number) => {
        queryClient.invalidateQueries(["carriersAssembly"]);
        setNotificationMessage(succesfullyMessage);
        onHide({
          shouldUpdate: true,
          close: false,
        });
      },
      onError: (error: Error) => {
        setError(error.message);
      },
      onSettled: () => setIsSubmitLoading(false),
    }
  );

  const onSubmit: SubmitHandler<Carrier> = async (data) => {
    setIsSubmitLoading(true);
    if (modal !== "disassembly")
      upsertMutation.mutate({
        data,
        onForceAssembly: forceAssembly,
      });
  };

  useEffect(() => {
    if (modal !== "disassembly") {
      reset(carrier || CARRIER_INITIAL_VALUES);
      setIsSubmitLoading(false);
    }
  }, [modal, carrier]);

  const StandId = watch("ScheduledLocationId");
  const SectionId = watch("ScheduledSectionId");

  const topRollId = watch("TopRollId");
  const bottomRollId = watch("BottomRollId");

  const topRoll = rolls?.find((roll) => roll.PartId === topRollId);
  const bottomRoll = rolls?.find((roll) => roll.PartId === bottomRollId);

  const validateRolls = (value: number | null, otherFieldName: any) => {
    const otherValue = watch(otherFieldName);
    return (
      value !== otherValue ||
      value === null ||
      value === undefined ||
      `Cannot be the same as ${
        otherFieldName === "TopRollId" ? "Top" : "Bottom"
      } Roll`
    );
  };

  return (
    <>
      {carrier === null && modal !== "disassembly" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select a carrier</Typography>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <Controller
                name="ScheduledSectionId"
                control={control}
                render={({ field }) => (
                  <SectionSelector
                    label="Section"
                    value={field.value}
                    onChange={field.onChange}
                    disabled={false}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="ScheduledLocationId"
                control={control}
                render={({ field }) => (
                  <LocationSelector
                    label="Stand Position"
                    value={field.value}
                    onChange={field.onChange}
                    onlyStands
                  />
                )}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <RollsetSelector
                filterSearch={{
                  SectionId: getValues("ScheduledSectionId"),
                  StandId: getValues("ScheduledLocationId"),
                  RollTypeCode: null,
                }}
                label="Rollset"
                value={null}
                setShowFilterModal={setShowFilterModal}
                disabled={
                  !getValues("ScheduledSectionId") &&
                  !getValues("ScheduledLocationId")
                    ? false
                    : true
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="TopRollId"
                control={control}
                rules={{
                  validate: {
                    crossValidate: (value) =>
                      validateRolls(value, "BottomRollId"),
                  },
                  deps: ["BottomRollId"],
                }}
                render={({ field, fieldState }) => (
                  <RollSelector
                    label="Top Roll"
                    value={field.value}
                    onChange={field.onChange}
                    filterRolls="T"
                    onForceAssembly={setForceAssembly}
                    params={{
                      sectionId: SectionId,
                      standId: StandId,
                    }}
                    fieldError={fieldState.error}
                    calledFrom="carrier"
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="BottomRollId"
                control={control}
                rules={{
                  validate: {
                    crossValidate: (value) => validateRolls(value, "TopRollId"),
                  },
                  deps: ["TopRollId"],
                }}
                render={({ field, fieldState }) => (
                  <RollSelector
                    label="Bottom Roll"
                    value={field.value}
                    onChange={field.onChange}
                    filterRolls="B"
                    onForceAssembly={setForceAssembly}
                    params={{
                      sectionId: SectionId,
                      standId: StandId,
                    }}
                    fieldError={fieldState.error}
                    calledFrom="carrier"
                  />
                )}
              />
            </Grid>
            {(topRoll?.RollTypeCode === "U" ||
              bottomRoll?.RollTypeCode === "U") && (
              <>
                <Grid item md={12} xs={12}>
                  <Controller
                    name="CassetteId"
                    control={control}
                    render={({ field }) => (
                      <CassetteSelector
                        label="Cassette"
                        value={field.value}
                        onChange={field.onChange}
                        disabled={false}
                        onForceAssembly={setForceAssembly}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item md={6} xs={12}>
              <Controller
                name="UMChockId"
                control={control}
                rules={{
                  validate: {
                    crossValidate: (value) =>
                      validateChocks(value, [
                        { Chock: "LM" },
                        { Chock: "UO" },
                        { Chock: "LO" },
                      ]),
                  },
                  deps: ["LMChockId", "LOChockId", "UMChockId"],
                }}
                render={({ field, fieldState: { error } }) => (
                  <ChockSelector
                    label="Top River Chock"
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    disabled={false}
                    onForceAssembly={setForceAssembly}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="UOChockId"
                control={control}
                rules={{
                  validate: {
                    crossValidate: (value) =>
                      validateChocks(value, [
                        { Chock: "LM" },
                        { Chock: "UM" },
                        { Chock: "LO" },
                      ]),
                  },
                  deps: ["LMChockId", "UMChockId", "LOChockId"],
                }}
                render={({ field, fieldState: { error } }) => (
                  <ChockSelector
                    label="Top Avenue Chock"
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    disabled={false}
                    onForceAssembly={setForceAssembly}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="LMChockId"
                control={control}
                rules={{
                  validate: {
                    crossValidate: (value) =>
                      validateChocks(value, [
                        { Chock: "UM" },
                        { Chock: "UO" },
                        { Chock: "LO" },
                      ]),
                  },
                  deps: ["UMChockId", "UOChockId", "LOChockId"],
                }}
                render={({ field, fieldState: { error } }) => (
                  <ChockSelector
                    label="Bottom River Chock"
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    disabled={false}
                    onForceAssembly={setForceAssembly}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="LOChockId"
                control={control}
                rules={{
                  validate: {
                    crossValidate: (value) =>
                      validateChocks(value, [
                        { Chock: "LM" },
                        { Chock: "UO" },
                        { Chock: "UM" },
                      ]),
                  },
                  deps: ["LMChockId", "UOChockId", "UMChockId"],
                }}
                render={({ field, fieldState: { error } }) => (
                  <ChockSelector
                    label="Bottom Avenue Chock"
                    value={field.value}
                    onChange={field.onChange}
                    fieldError={error}
                    disabled={false}
                    onForceAssembly={setForceAssembly}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="EntryGuideId"
                control={control}
                render={({ field }) => (
                  <GuideSelector
                    label="Entry Guide"
                    typeCode="I"
                    value={field.value}
                    onChange={field.onChange}
                    disabled={false}
                    onForceAssembly={setForceAssembly}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="ExitGuideId"
                control={control}
                render={({ field }) => (
                  <GuideSelector
                    label="Delivery Guide"
                    typeCode="O"
                    value={field.value}
                    onChange={field.onChange}
                    disabled={false}
                    onForceAssembly={setForceAssembly}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="Bait"
                control={control}
                render={({ field }) => (
                  <TextField
                    value={field.value ?? ""}
                    onChange={field.onChange}
                    label="Bait"
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="WeighUp"
                control={control}
                render={({ field }) => (
                  <TextField
                    value={field.value ?? ""}
                    onChange={field.onChange}
                    label="Weight Up"
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              style={{ margin: 15 }}
            >
              <FooterDetailsButtons
                isSubmitLoading={isSubmitLoading}
                disabled={!isFormDirty}
                onHide={() => onHide({ shouldUpdate: false, close: true })}
              />
            </Grid>
          </Grid>
        </form>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
      <FilterRollModal
        show={showFilterModal}
        onHide={handleHideFilter}
        filterSearch={{
          SectionId: getValues("ScheduledSectionId"),
          StandId: getValues("ScheduledLocationId"),
          RollTypeCode: null,
        }}
        selectedRolls={selectedRolls}
        setSelectedRolls={setSelectedRolls}
        selectedRollset={selectedRollset}
        setSelectedRollset={setSelectedRollset}
      />
    </>
  );
};
