import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { SpareCarrier } from "../models/SpareCarrier";

export const getReadyCarriers = async (
  sectionId: number,
  isNextSection?: boolean
) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [
    { name: "SectionId", value: sectionId ?? 0 },
  ];

  if (isNextSection) {
    parameters.push({ name: "IsNextSection", value: isNextSection });
  }

  const resp = await apiService.callV2("[M2].[GetReadyCarriers]", parameters);

  if (resp.ok) {
    let rows = get(resp, "data.tables[0].rows", []);

    rows = rows.map((s: SpareCarrier) => {
      const sparesNames = s.Spares?.split(",");
      const sparesStatuses = s.SparesStatus?.split(",");

      const SparesFormatted = sparesNames
        ?.map((name, index) => ({
          name,
          status: sparesStatuses ? sparesStatuses[index] : "",
        }))
        .filter(
          (spare) => spare.name.trim() !== "" && spare.status.trim() !== ""
        );

      return {
        ...s,
        SparesFormatted,
      };
    });

    return rows as SpareCarrier[];
  } else {
    throw new Error(resp.message || "Error fetching carriers");
  }
};

export const getCurrentSection = async () => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [];

  const resp = await apiService.callV2("[M2].[GetCurrentSection]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as [];
  } else {
    throw new Error(resp.message || "Error fetching current section");
  }
};

export const upsertSpare = async ({
  carrier,
  comments,
}: {
  carrier: SpareCarrier;
  comments: string;
}) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [
    { name: "PartId", value: carrier.PartId },
    { name: "Comments", value: comments },
  ];

  const resp = await apiService.callV2("[M2].[UpsertSpare]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as [];
  } else {
    throw new Error(resp.message || "Error upserting spare");
  }
};
