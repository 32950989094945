import { Suspense } from "react";
import { Route } from "react-router-dom";
import { LazyLoading } from "../../controls/LazyLoading";
import CarrierAssembly from "../../pages/assembly/carriers";
import Rollset from "../../pages/assembly/rollsets";
import Spare from "../../pages/assembly/spare";

export const AssemblyRoutes = [
  <Route
    path="/carriers-assembly"
    element={
      <Suspense fallback={<LazyLoading></LazyLoading>}>
        <CarrierAssembly />
      </Suspense>
    }
  />,
  <Route
    path="/rollsets"
    element={
      <Suspense fallback={<LazyLoading></LazyLoading>}>
        <Rollset />
      </Suspense>
    }
  />,
  <Route
    path="/spare"
    element={
      <Suspense fallback={<LazyLoading></LazyLoading>}>
        <Spare />
      </Suspense>
    }
  />,
];
