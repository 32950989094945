import { MesfModal } from "@dexteel/mesf-core";
import { Button, Chip, Grid, TextField } from "@material-ui/core";
import { useState } from "react";
import { Section } from "../models/Section";
import { SectionsOfPartSelector } from "./SectionsOfPartSelector";

type Props = {
  label: string;
  onSelectionChanged: Function;
  sectionsOfPart: Section[];
  disabled?: boolean;
};

export const SectionField = ({
  label,
  onSelectionChanged,
  sectionsOfPart,
  disabled = false,
}: Props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <TextField
        label={label}
        onClick={() => setOpenModal(true)}
        variant="outlined"
        fullWidth
        size="small"
        InputProps={{
          startAdornment: sectionsOfPart.map((section) => (
            <Chip
              label={section.SectionName}
              size="small"
              color="primary"
              variant="outlined"
              style={{ margin: "2px" }}
            />
          )),
        }}
      />
      <MesfModal
        title="CHOOSE SECTIONS"
        open={openModal}
        handleClose={() => setOpenModal(false)}
      >
        <MesfModal.Content dividers enterKeyHint={"Enter"}>
          <SectionsOfPartSelector
            sectionsOfPart={sectionsOfPart}
            onSelectionChanged={onSelectionChanged}
          />
        </MesfModal.Content>
        <MesfModal.Actions
          style={{ padding: "20px 30px" }}
          enterKeyHint={"Enter"}
        >
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item md={3} xs={12} style={{ margin: 0 }}>
              <Button
                fullWidth
                variant="contained"
                color="default"
                onClick={() => setOpenModal(false)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item md={3} xs={12} style={{ margin: 0 }}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => setOpenModal(false)}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </MesfModal.Actions>
      </MesfModal>
    </>
  );
};
